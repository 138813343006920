.page-header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 20px 0;
  z-index: 1;
  background-color: transparent;
  height: 80px;
  color: #fff;
  /*rgba(255, 255, 255, 0.15);*/
  /* height: 300px; */
}

.page-header.is-sticky {
  position: fixed;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  backdrop-filter: blur(10px);
  background-color: #fff;
  color: #000;
  animation: backgroundOpacity 0.35s ease-out;
  /*slideDown 0.35s ease-out;*/
  height: 80px;
}

@keyframes backgroundOpacity {
  from {
    background-color: transparent;
    color: #fff;
  }

  to {
    background-color: #fff;
    color: #000;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

.page-header nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main {
  margin-top: 300px;
}

.container {
  max-width: 1200px;
  margin: auto;

}

@media (max-width: 600px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

}



.header__container {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

.header__container nav {
  width: 100%;
}

.header__container nav a {
  text-decoration: none;
  color: inherit;
}

.menu {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
}


.menu--mobile>button>svg {
  color: #fff;
}

.page-header.is-sticky .menu--mobile>button>svg {
  color: #000;
}

.big__banner {
  height: 100vh;
  width: 100vw;
  background-image: url("../public/assets/img/bg.png");
  background-size: cover;
  backdrop-filter: blur(300px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
}

.big__banner__title {
  font-size: 6rem;
  font-weight: 700;
  margin-bottom: 20px;
}

.big__banner__subtitle {
  font-size: 3rem;
  font-weight: 400;
  margin-bottom: 20px;
}

.about__container {
  background-color: #219EBC60;
  background-color: #e0e1dd;
  width: 100vw;
  color: #000;
}

.about__content,
.contact__content,
.skills__content,
.projects__content {
  padding-top: 50px;
  padding-bottom: 50px;
}

.about__title__divider {
  height: 5px;
  width: 100px;
  background-color: #457b9d;
}

.footer {
  background-color: #cdcdcd60;
  width: 100vw;
  color: #fff;
}

.footer__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
}

.skills__container {
  background-color: #219EBC60;
  width: 100vw;

  color: #fff;
}



.skills__title__divider {
  height: 5px;
  width: 100px;
  background-color: #cdcdcd;
}

.skills__paper {
  padding: 20px;
  margin-top: 10px;
}

.skill__img {

  width: 75%;
  margin: auto;
  background-color: #fff;
  margin-bottom: 20px;
}

.projects__container {
  background-color: #219EBC60;
  background-color: #e0e1dd;
  width: 100vw;
  height: 80vh;
  color: #000;
}



.projects__title__divider {
  height: 5px;
  width: 100px;
  background-color: #457b9d;
}

.contact__container {
  background-color: #457b9d;
  width: 100vw;

  color: #fff;
}


.contact__title__divider {
  height: 5px;
  width: 100px;
  background-color: #cdcdcd;
}


.footer__icon {
  color: #fff;
}

html {
  scroll-behavior: smooth;
}